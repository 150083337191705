'use client'
import { SearchType } from '@/app/(site)/search/types'
import MediumRectangleAd from '@/components/ads/medium-rectangle'
import UAParserContext from '@/context/ua-parser'
import { Interface, NexaBlack } from '@/styles/fonts'
import { IGif } from '@giphy/js-types'
import { useContext, useRef } from 'react'
import styled, { css } from 'styled-components'
import _PageTitle from 'ui/src/components/page-title'
import { desktop } from 'ui/src/css'
import SearchGrids from '../../search/[term]/search-grids'
import SearchToggle from '../../search/[term]/search-toggle'
import Koko from '../../search/koko'
import { KokoResponse } from '../../search/koko/api'

const PageTitle = styled(_PageTitle)`
    font-family: ${Interface.style.fontFamily};
    ${desktop(css`
        margin: 21px 0;
        font-size: 36px;
        font-family: ${NexaBlack.style.fontFamily};
    `)}
`

type Props = {
    type: SearchType
    decodedTerm: string
    initialGifs?: IGif[]
    kokoResponse?: KokoResponse
    headerTypeSuffix: string
}
const Contents = ({ type, decodedTerm, initialGifs, kokoResponse, headerTypeSuffix }: Props) => {
    const { current: initialSearchTerm } = useRef(decodedTerm)
    const { deviceType } = useContext(UAParserContext)

    return (
        <>
            <PageTitle className={'mb-2'}>
                Explore <PageTitle.HighLight>{initialSearchTerm}</PageTitle.HighLight> {headerTypeSuffix}
            </PageTitle>
            {deviceType === 'mobile' ? (
                <>
                    <Koko preFetchedResponse={kokoResponse} term={initialSearchTerm} />
                </>
            ) : (
                <>
                    <MediumRectangleAd />
                    <Koko preFetchedResponse={kokoResponse} term={initialSearchTerm} />
                </>
            )}
            <SearchToggle
                initialSearchTerm={initialSearchTerm}
                routerType={type}
                targetPage="explore"
                className="mb-2"
            />
            <SearchGrids initialGifs={initialGifs} term={decodedTerm} />
        </>
    )
}

export default Contents
